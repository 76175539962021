import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Text, Spinner, useColorModeValue } from '@chakra-ui/react'; // Add useColorModeValue here
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Header } from "../../components/Header";
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate } from "react-router-dom";

const EditorInstall = () => {
  const navigate = useNavigate()
  const [status, setStatus] = useState(null); // success or error
  const [editorLink, setEditorLink] = useState('');
  const [loading, setLoading] = useState(true);

  // Fetch the editor link and update status
  useEffect(() => {
    const installTranslator = async () => {
      try {
        const response = await axiosInstance.post('/weblang/api/installations');
        setEditorLink(response.data.editor_link);
        setStatus('success');
      } catch (error) {
        setStatus('error');
      } finally {
        setLoading(false);
      }
    };
    installTranslator();
  }, []);

  const handleSuccessClick = () => {
    window.location.href = editorLink; // Redirect to editor link on success
  };

  // Extract colors from Chakra's useColorModeValue at the top level
  const checkColor = useColorModeValue('blue.600', 'blue.400');
  const crossColor = useColorModeValue('red.400', 'red.400');
  const textColor = useColorModeValue('gray.600', 'gray.400');

  if (loading) {
    return (
      <Box textAlign="center" py="10">
        <Spinner size="xl" />
        <Text mt="4">Installing Translator...</Text>
      </Box>
    );
  }

  return (
    <>
      <Box as="section" py="10">
        <Flex maxW={{ base: '600px' }} mx="auto" px={{ base: '6', md: '8' }} direction="column" align="center" textAlign="center">
          {status === 'success' && (
            <>
              <FaCheck size="100px" color='#2b6cb0' />
              <Heading mt="6" color={checkColor} fontSize="4xl">
                Translator Installed
              </Heading>
              <Text mt="4" fontSize="lg" color={textColor}>
                Your AI-powered translation tool is successfully installed and ready to break language barriers.
                To complete the setup, please proceed to install the language selector.
              </Text>
              <Button
                mt="8"
                size="lg"
                colorScheme="blue"
                onClick={() => navigate('/weblang/languages')}
                width="400px"
              >
                Finish
              </Button>
            </>
          )}

          {status === 'error' && (
            <>
              <FaTimes size="100px" color="#f56565" />
              <Heading mt="6" color={crossColor} fontSize="4xl">
                Installation Failed
              </Heading>
              <Text mt="4" fontSize="lg" color={textColor}>
                We encountered an unexpected issue while setting up the translation tool on your website.
                Please contact our support team at <b>andrew@rubyroid.tech</b> for assistance. We're here to help get you up and running smoothly!
              </Text>
            </>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default EditorInstall;
