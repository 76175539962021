import React, { useState, useEffect } from 'react'
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Textarea,
  useColorModeValue as mode,
  Heading,
  HStack,
  Text,
  Flex,
  Spinner,
  Alert,
  AlertIcon,
  IconButton,
  Select,
  Input,
  Button,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { HiArrowLeft } from 'react-icons/hi'
import { SearchIcon } from '@chakra-ui/icons'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import debounce from 'lodash.debounce'
import axiosInstance from '../../utils/axiosInstance'

const EditTranslation = () => {
  const { languageId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [translations, setTranslations] = useState([])
  const [pages, setPages] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertStatus, setAlertStatus] = useState('success')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const itemsPerPage = 50

  const queryParams = new URLSearchParams(location.search)
  const [selectedPage, setSelectedPage] = useState(queryParams.get('page') || '')
  const [searchQuery, setSearchQuery] = useState(queryParams.get('query') || '')

  const debouncedFetchFilteredTranslations = debounce(() => {
    updateUrlParams()
    fetchFilteredTranslations()
  }, 300)

  const updateUrlParams = () => {
    const params = new URLSearchParams()
    if (selectedPage) params.set('page_path', selectedPage)
    if (searchQuery) params.set('query', searchQuery)
    params.set('page', currentPage)
    navigate({ search: params.toString() }, { replace: true })
  }

  const fetchFilteredTranslations = async () => {
    setLoading(true)
    try {
      const response = await axiosInstance.get(`/weblang/api/languages/${languageId}/page_translations`, {
        params: {
          page_path: selectedPage,
          query: searchQuery,
          page: currentPage,
          per_page: itemsPerPage
        },
      })
      setTranslations(response.data.page_translations)
      setTotalPages(response.data.pagination.total_pages)
    } catch (error) {
      setError('Failed to load filtered translations.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pagesResponse = await axiosInstance.get(`/weblang/api/languages/${languageId}/page_translations/page_options`)
        setPages(pagesResponse.data.pages)
        await fetchFilteredTranslations()
      } catch (error) {
        setError('Failed to load data.')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [languageId])

  const handlePageSelect = (e) => {
    setSelectedPage(e.target.value)
    setCurrentPage(1) // Reset to the first page when changing filters
    debouncedFetchFilteredTranslations()
  }

  const handleSearchClick = () => {
    setCurrentPage(1) // Reset to the first page on new search
    debouncedFetchFilteredTranslations()
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    fetchFilteredTranslations()
  }

  const handleInputChange = (id, value) => {
    setTranslations((prevTranslations) =>
      prevTranslations.map((translation) =>
        translation.id === id
          ? { ...translation, translation: value, edited: true }
          : translation
      )
    )
  }

  const handleSave = async (id) => {
    const translationToUpdate = translations.find((translation) => translation.id === id)
    try {
      await axiosInstance.put(`/weblang/api/languages/${languageId}/page_translations/${id}`, {
        translation: translationToUpdate.translation,
      })
      setAlertMessage('Translation saved successfully.')
      setAlertStatus('success')
      setTranslations((prevTranslations) =>
        prevTranslations.map((translation) =>
          translation.id === id ? { ...translation, edited: false } : translation
        )
      )
    } catch (error) {
      setAlertMessage('Failed to save the translation. Please try again.')
      setAlertStatus('error')
    }
  }

  return (
    <Box bg={mode('gray.100', 'gray.700')} minHeight="100vh">
      <Box maxW="7xl" mx="auto" px={{ base: '6', md: '8' }}>
        <HStack>
          <IconButton
            icon={<HiArrowLeft />}
            aria-label="Back to Languages"
            onClick={() => navigate('/weblang/languages')}
            mr={1}
          />
          <Heading size="lg">Manage Translations</Heading>
        </HStack>
      </Box>

      <Box as="section" py="6">
        <Box maxW="7xl" mx="auto" px={{ base: '6', md: '8' }}>
          {alertMessage && (
            <Alert status={alertStatus} mb="6">
              <AlertIcon />
              {alertMessage}
            </Alert>
          )}

          {loading ? (
            <Box textAlign="center" py="10">
              <Spinner size="xl" />
              <Text mt="4">Loading Translations...</Text>
            </Box>
          ) : (
            <>
              <Flex mb="4" justify="space-between" align="center" w="100%">
                <Select
                  placeholder="Filter by Page"
                  value={selectedPage}
                  onChange={handlePageSelect}
                  bg="white"
                  maxW="200px"
                >
                  {pages.map((page) => (
                    <option key={page} value={page}>
                      {page}
                    </option>
                  ))}
                </Select>
                <InputGroup w="full" ml={4}>
                  <Input
                    placeholder="Search translations"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    bg="white"
                  />
                  <InputRightElement>
                    <Button
                      bg="blue.500"
                      color="white"
                      _hover={{ bg: "blue.600" }}
                      onClick={handleSearchClick}
                      p={2}
                    >
                      <SearchIcon boxSize="1em" />
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>

              <TableContainer bg="white" borderRadius="md">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>English</Th>
                      <Th>Translation</Th>
                      <Th>Path</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {translations.map((translation) => (
                      <Tr key={translation.id}>
                        <Td maxWidth="400px" whiteSpace="normal" wordBreak="break-word">
                          {translation.text}
                        </Td>
                        <Td>
                          <Textarea
                            value={translation.translation}
                            onChange={(e) =>
                              handleInputChange(translation.id, e.target.value)
                            }
                            rows={3}
                          />
                        </Td>
                        <Td>{translation.page_path}</Td>
                        <Td>
                          <Button
                            colorScheme="blue"
                            size="sm"
                            isDisabled={!translation.edited}
                            onClick={() => handleSave(translation.id)}
                          >
                            Save
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>

              <Flex mt={4} justify="center">
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  isDisabled={currentPage === 1}
                >
                  Previous
                </Button>
                <Text mx={4} alignSelf="center">
                  Page {currentPage} of {totalPages}
                </Text>
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  isDisabled={currentPage === totalPages || totalPages === 0}
                >
                  Next
                </Button>
              </Flex>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EditTranslation
