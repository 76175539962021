// Header.tsx
import {
  Box,
  Flex,
  HStack,
  useColorModeValue as mode,
  VisuallyHidden,
  Image,
} from '@chakra-ui/react'
import * as React from 'react'
import { MobileNav } from './Header/MobileNav'
import { NavLink } from './Header/NavLink'

interface LinkItem {
  label: string
  href: string
  icon?: React.ElementType
}

interface HeaderProps {
  logo: string // Expecting a string (URL or path to the image)
  links?: LinkItem[]
  activeLink?: string
  actionsComponent?: React.ReactNode // New optional prop for custom actions
}

export const Header: React.FC<HeaderProps> = ({
                                                logo,
                                                links,
                                                activeLink,
                                                actionsComponent,
                                              }) => {
  return (
    <Box as="header" bg={mode('white', 'gray.800')} borderBottomWidth="1px">
      <Box maxW="7xl" mx="auto" py="4" px={{ base: '6', md: '8' }}>
        <Flex as="nav" justify="space-between" align="center">
          {/* Logo and Desktop Links */}
          <HStack spacing="8">
            <Box as="a" href="/weblang/languages" rel="home">
              <VisuallyHidden>Weblang</VisuallyHidden>
              <Image src={logo} alt="Weblang Logo" h="8" /> {/* Render logo as image */}
            </Box>
            <HStack display={{ base: 'none', lg: 'flex' }} spacing="8">
              {links && links.map((link) => (
                  <NavLink.Desktop
                      key={link.label}
                      href={link.href}
                      active={activeLink === link.href}
                  >
                    {link.label}
                  </NavLink.Desktop>
              ))}
            </HStack>
          </HStack>

          {/* Custom Actions or Empty */}
          <Flex align="center">
            <HStack spacing="8" display={{ base: 'none', md: 'flex' }}>
              {actionsComponent ? actionsComponent : null} {/* Render custom actions if provided */}
            </HStack>
            <Box ml="5">
              <MobileNav links={ links || [] } />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}
