import { datadogRum } from '@datadog/browser-rum';

const datadogMiddleware = () => {
  // Initialize Datadog RUM
  if (process.env.NODE_ENV === 'production') {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: process.env.REACT_APP_DATADOG_SITE,
      service: 'rubyroid-ui',
      env: process.env.NODE_ENV, // Use 'production', 'development', etc.
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      usePartitionedCrossSiteSessionCookie: true,
      trackSessionAcrossSubdomains: true
    });

    // Start session replay recording if needed
    datadogRum.startSessionReplayRecording();
  }
};

export default datadogMiddleware;
