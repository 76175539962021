import React from 'react';
import { Box, Container, Heading, Text, Button, Stack, Icon } from '@chakra-ui/react';
import { FiTool } from 'react-icons/fi'; // Importing a relevant icon from react-icons

const Index = () => {
  return (
    <Container maxW="container.xl" py={16} centerContent>
      <Stack spacing={8} align="center">
        {/* Adding a large icon in the center */}
        <Icon as={FiTool} boxSize={32} color="teal.500" />

        <Heading as="h1" size="2xl" textAlign="center" color="teal.500">
          Weblang is Under Reconstruction
        </Heading>
        <Text fontSize="xl" color="teal.600" textAlign="center">
          We are working hard to improve your experience. Please come back on
          <Text as="span" fontWeight="bold">
            {' '}October 14th.
          </Text>
        </Text>
      </Stack>
    </Container>
  );
};

export default Index;
